export const calculatePriceCalculation = (requestedQuantity, product, priceScales, hasSurcharges = false, expectedPrice = null) => {
  if (!requestedQuantity || !product?.materialPrice?.priceQuantity || !priceScales || !Array.isArray(priceScales) || priceScales.length < 1) {
    const customExpectedPrice = expectedPrice ? expectedPrice / 100 : null
    return {
      Sku: product?.sku ?? null,
      RequestedQuantity: requestedQuantity,
      SuggestedQuantity: null,
      pricePerPiece: null,
      pricePer100: null,
      priceForRequestedQuantity: expectedPrice ? customExpectedPrice * requestedQuantity : null
    }
  }
  const priceScaleRecommended = findFittingPriceScale(requestedQuantity, priceScales, product)

  if (hasSurcharges && !expectedPrice) {
    return {
      Sku: product.sku,
      RequestedQuantity: requestedQuantity,
      SuggestedQuantity: priceScaleRecommended === null ? null : recommendedQuantityCalc(priceScaleRecommended, product),
      pricePerPiece: product.materialPrice.pricePer100 === 0 ? null : product.materialPrice.pricePer100 / product.materialPrice.priceQuantity,
      pricePer100: product.materialPrice.pricePer100 === 0 ? null : product.materialPrice.pricePer100,
      priceForRequestedQuantity: product.materialPrice.price === 0 ? null : product.materialPrice.price
    }
  }

  // fill segments with optimized value
  if (priceScaleRecommended === null) {return {
    Sku: product?.sku ?? null,
    RequestedQuantity: requestedQuantity,
    SuggestedQuantity: null,
    pricePerPiece: null,
    pricePer100: null,
    priceForRequestedQuantity: null
  }}
  const recommendedQuantity = recommendedQuantityCalc(priceScaleRecommended, product)

  // find segment with chosen quantity
  const priceScaleRequested = findPriceScale(requestedQuantity, priceScales)
  if (priceScaleRequested !== null) {
    const totalPricePerPiece = expectedPrice === null ? priceScaleRequested?.price / product.materialPrice.priceQuantity : expectedPrice / 100
    const pricePerPiece = priceScaleRequested?.price / product.materialPrice.priceQuantity

    return {
      Sku: product.sku,
      RequestedQuantity: requestedQuantity,
      SuggestedQuantity: recommendedQuantity,
      pricePerPiece: pricePerPiece,
      pricePer100: pricePerPiece * 100,
      priceForRequestedQuantity: totalPricePerPiece * requestedQuantity
    }
  }

  if (expectedPrice) {
    return {
      Sku: product.sku,
      RequestedQuantity: requestedQuantity,
      SuggestedQuantity: recommendedQuantity,
      pricePerPiece: expectedPrice,
      pricePer100: expectedPrice / 100,
      priceForRequestedQuantity: expectedPrice * requestedQuantity
    }
  } else {
    return {
      Sku: product.sku,
      RequestedQuantity: requestedQuantity,
      SuggestedQuantity: recommendedQuantity,
      pricePerPiece: null,
      pricePer100: null,
      priceForRequestedQuantity: null
    }
  }
}

const findFittingPriceScale = (quantity, priceScales, product) => {
  if (!quantity || !priceScales || !Array.isArray(priceScales)) {return null}
  let chosenSegment = null
  let currentMinItemValueApplied = null

  if (product.materialPrice.priceQuantity !== 0) {
    priceScales.forEach((currentPriceSaleSegment) => {
      const minValue = (currentPriceSaleSegment.quantity * currentPriceSaleSegment.price) / product.materialPrice.priceQuantity
      const minItemValueApplied = minValue <= product.materialPrice.minimumOrderLineValue
        ? currentPriceSaleSegment.quantity : 0
      if ((currentMinItemValueApplied === null || minItemValueApplied > currentMinItemValueApplied)) {
        currentMinItemValueApplied = minItemValueApplied
        chosenSegment = currentPriceSaleSegment
      }
    })
  }

  return chosenSegment
}

const findPriceScale = (quantity, priceScales) => {
  const priceScalesReversed = [...priceScales].reverse()
  const maxQuantity = priceScalesReversed[0].maxQuantity ?? priceScalesReversed[0].quantity
  const isOngoing = priceScalesReversed[0].isOngoing
  if (quantity > maxQuantity) {
    if (isOngoing) {
      return priceScalesReversed[0]
    }
    return null
  }
  return priceScalesReversed.find((currentPriceSaleSegment) => currentPriceSaleSegment.quantity <= quantity)
}

const recommendedQuantityCalc = (priceScale, product) => {
  const correspondingPrice = priceScale.price / product.materialPrice.priceQuantity
  const optimalQuantity = Math.floor((product.materialPrice.minimumOrderLineValue ?? 0) / correspondingPrice)
  return product.packagingUnit !== 0 ? Math.floor(optimalQuantity / product.packagingUnit) * product.packagingUnit : optimalQuantity
}
