<template>
  <div
    class="skeleton__availability"
  >
    <div class="skeleton__availability__table">
      <div
        class="skeleton__availability__table__header"
      >
        <Skeleton class="skeleton__availability__table__header__text" />
        <Skeleton class="skeleton__availability__table__header__text" />
        <Skeleton class="skeleton__availability__table__header__text" />
      </div>
      <div
        class="skeleton__availability__table__content"
      >
        <div
          class="skeleton__availability__table__content__row"
          v-for="row in dummyArray"
          :key="row.index"
        >
          <Skeleton class="skeleton__availability__table__content__row__text" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Skeleton} from '@progress/kendo-vue-indicators'

export default {
  components: {
    Skeleton
  },
  data() {
    let dummyArray = new Array(10).fill({index: 0}).map((e, i) => e.index = i)
    return {
      dummyArray: dummyArray
    }
  }
}
</script>
