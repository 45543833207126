<template>
  <div
    class="skeleton__availability"
  >
    <div class="skeleton__availability__header">
      <Skeleton class="skeleton__availability__header" />
    </div>
    <Skeleton class="skeleton__availability__text" />

    <div class="skeleton__availability__input">
      <Skeleton class="skeleton__availability__input__forms" />
      <Skeleton class="skeleton__availability__input__forms" />
    </div>
    <div class="skeleton__availability__buttons">
      <Skeleton
        class="skeleton__availability__buttons__request"
        :shape="'rectangle'"
      />
      <Skeleton
        class="skeleton__availability__buttons__buy"
        :shape="'rectangle'"
      />
    </div>
    <div class="skeleton__availability__table">
      <price-scales-skeleton v-if="showPriceScales" />
    </div>
  </div>
</template>
<script>
import {Skeleton} from '@progress/kendo-vue-indicators'
import PriceScalesSkeleton from './PriceScalesSkeleton.vue'

export default {
  components: {
    Skeleton,
    PriceScalesSkeleton
  },
  props: {
    showPriceScales: {
      type: Boolean,
      default () {
        return false
      }
    }
  }
}
</script>
