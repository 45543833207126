export const usePartnerHubHelper = () => {
  const loadCRMFormCapture = (formId: string) => {
    const script = document.createElement('script')
    script.src = 'https://cxppusa1formui01cdnsa01-endpoint.azureedge.net/eur/FormCapture/FormCapture.bundle.js'
    script.async = true

    script.onload = () => {
      setupEventListener(d365mktformcapture, formId)
    }

    document.body.appendChild(script)
  }

  const getLanguageId = (locale: string) => {
    switch (locale) {
    case 'en': return 'a4822068-28d4-e311-80c6-0050568842f2'
    case 'de': return 'ffbbe0cf-28d4-e311-80c6-0050568842f2'
    case 'fr': return '36dfa3c3-28d4-e311-80c6-0050568842f2'
    case 'it': return 'd1c570df-28d4-e311-80c6-0050568842f2'
    case 'es': return 'ee9057f6-28d4-e311-80c6-0050568842f2'
    default: return 'a4822068-28d4-e311-80c6-0050568842f2'
    }
  }

  return {
    loadCRMFormCapture,
    getLanguageId
  }
}

const setupEventListener = (d365mktformcapture: any, formId: string) => {
  d365mktformcapture.waitForElement('#' + formId)
    .then((form: any) => {
      const mappings = [
        {
          FormFieldName: 'firstName',
          DataverseFieldName: 'firstname'
        },
        {
          FormFieldName: 'lastName',
          DataverseFieldName: 'lastname'
        },
        {
          FormFieldName: 'companyName',
          DataverseFieldName: 'companyname'
        },
        {
          FormFieldName: 'email',
          DataverseFieldName: 'emailaddress1'
        },
        {
          FormFieldName: 'phone',
          DataverseFieldName: 'telephone1'
        },
        {
          FormFieldName: 'comments',
          DataverseFieldName: 'comments'
        },
        {
          FormFieldName: 'description',
          DataverseFieldName: 'description'
        },
        {
          FormFieldName: 'country',
          DataverseFieldName: 'zz_country1'
        },
        {
          FormFieldName: 'privacyPolicy',
          DataverseFieldName: 'msdynmkt_purposeid;channels;optinwhenchecked',
          DataverseFieldValue: '61b53cd6-6556-49f1-9217-da244bea7751;Email;true'
        },
        {
          FormFieldName: 'marketingLanguage',
          DataverseFieldName: 'zz_marketinglanguage'
        },
        {
          FormFieldName: 'initialIndustry',
          DataverseFieldName: 'zz_initialindustry'
        },
        {
          DataverseFieldName: 'zz_segment',
          DataverseFieldValue: '{"Id":"","LogicalName":"zz_segment"}'
        },
        {
          FormFieldName: 'subject',
          DataverseFieldName: 'subject'
        }

      ]

      form.addEventListener('captureForm', () => {
        const serializedForm = d365mktformcapture.serializeForm(form, mappings)
        const payload = serializedForm.SerializedForm.build()

        const captureConfig = {
          FormId: 'c5eac0cb-6f90-ef11-8a69-000d3ab85655',
          FormApiUrl: 'https://public-eur.mkt.dynamics.com/api/v1.0/orgs/526b3040-ce06-41d6-8fcd-9101566ddae1/landingpageforms'
        }
        d365mktformcapture.submitForm(captureConfig, payload)
      }, true)
    })
}
